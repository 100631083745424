var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "body-class": "pt-1 pb-0 mb-0 px-0"
    }
  }, _vm._l(_vm.flatSegmentSelected, function (segment, segmentIndex) {
    var _vm$getSeatsMap, _vm$getSeatsMap$dataA, _vm$getSeatsMap$dataA2, _vm$getSeatsMap2, _vm$getSeatsMap2$data, _vm$getSeatsMap2$data2;
    return _c('div', {
      key: segmentIndex
    }, [['VN1A', 'VU', 'VJ', 'VZ', 'AK', 'QH'].includes(segment.source) ? _c('b-card', {
      staticClass: "border-secondary",
      attrs: {
        "header-bg-variant": "light-info bg-light-primary",
        "header-class": "info",
        "body-class": "py-75 px-0"
      }
    }, [_c('app-collapse', {
      attrs: {
        "visible": true
      }
    }, [_c('app-collapse-item', {
      attrs: {
        "title": "",
        "body-class": "px-0"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex align-items-center w-100"
          }, [_c('IAmLogoAirline', {
            attrs: {
              "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
              "size": "sm",
              "custom-class": "mr-50"
            }
          }), _c('span', {
            staticClass: "font-weight-bolder font-medium-1 ml-1"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightSegment')) + ": "), _c('b', {
            staticClass: "text-airline"
          }, [_vm._v(_vm._s(segment.segmentFlights))]), _vm.isEmpty(_vm.getSeatsMap(segment).seatFormat) ? _c('i', {
            staticClass: "text-warning ml-1"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.notSupportSeat')) + " ")]) : _vm._e()])], 1)];
        },
        proxy: true
      }], null, true)
    }, [['QH'].includes(segment.source) ? _c('b-alert', {
      staticClass: "py-50 px-1 shadow-sm fw-700 d-flex-center my-75",
      attrs: {
        "show": "",
        "variant": "danger"
      }
    }, [_c('b', [_vm._v("Bamboo Airways không hỗ trợ mua ghế khi thanh toán ngay, đại lý vui lòng chọn giữ chỗ nếu cần chọn ghế.")])]) : _vm._e(), _c('b-row', {
      staticClass: "mx-0 pt-1 border-top-secondary"
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('b-table', {
      staticClass: "text-center border",
      attrs: {
        "striped": "",
        "hover": "",
        "responsive": "",
        "items": _vm.filterSeatBySegment(segment),
        "fields": _vm.selectedSeatFields,
        "show-empty": "",
        "empty-text": _vm.$t('noMatchingResult')
      },
      scopedSlots: _vm._u([{
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('span', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(data.item.passenger.paxType) + " #" + _vm._s(data.index + 1) + " ")])];
        }
      }, {
        key: "cell(seat)",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {}, [item.seat ? _c('b-button', {
            staticClass: "px-75",
            attrs: {
              "variant": "success",
              "disabled": true
            }
          }, [_vm._v(" " + _vm._s(item.seat.seat.seatName) + " ")]) : _c('span', [_vm._v(" - ")])], 1)];
        }
      }, {
        key: "cell(price)",
        fn: function fn(_ref2) {
          var item = _ref2.item;
          return [item.seat && item.seat.seat && !_vm.isEmpty(item.seat.seat.fares) ? _c('span', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.seat.seat.fares[0].total)) + " ")]) : _c('span', [_vm._v(" - ")])];
        }
      }, {
        key: "cell(action)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_c('div', {
            staticClass: "d-flex flex-nowrap justify-content-center"
          }, [item.seat ? _c('b-button', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(234, 84, 85, 0.15)',
              expression: "'rgba(234, 84, 85, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "btn-icon rounded-circle",
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeleteSeat(item);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "TrashIcon"
            }
          })], 1) : _c('span', [_vm._v(" - ")])], 1)];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "12",
        "lg": "7"
      }
    }, [_vm.getSeatsMap(segment) ? _c('b-row', {
      staticClass: "mx-0",
      attrs: {
        "no-gutters": ""
      }
    }, [_vm.getSeatsMap(segment).dataAirCraftBySegment ? _c('b-col', {
      staticClass: "text-center my-2",
      attrs: {
        "cols": "12"
      }
    }, [_c('p', {
      staticClass: "text-airline font-weight-bolder font-large-1 mb-1"
    }, [_vm._v(" " + _vm._s(_vm.getAirCraftByIata((_vm$getSeatsMap = _vm.getSeatsMap(segment)) === null || _vm$getSeatsMap === void 0 ? void 0 : (_vm$getSeatsMap$dataA = _vm$getSeatsMap.dataAirCraftBySegment) === null || _vm$getSeatsMap$dataA === void 0 ? void 0 : (_vm$getSeatsMap$dataA2 = _vm$getSeatsMap$dataA.seatsMap) === null || _vm$getSeatsMap$dataA2 === void 0 ? void 0 : _vm$getSeatsMap$dataA2.aircraft) || ((_vm$getSeatsMap2 = _vm.getSeatsMap(segment)) === null || _vm$getSeatsMap2 === void 0 ? void 0 : (_vm$getSeatsMap2$data = _vm$getSeatsMap2.dataAirCraftBySegment) === null || _vm$getSeatsMap2$data === void 0 ? void 0 : (_vm$getSeatsMap2$data2 = _vm$getSeatsMap2$data.seatsMap) === null || _vm$getSeatsMap2$data2 === void 0 ? void 0 : _vm$getSeatsMap2$data2.aircraft)) + " ")]), _c('div', {
      staticClass: "d-flex justify-content-center"
    }, [_c('div', {
      staticClass: "mr-1",
      staticStyle: {
        "width": "100px",
        "height": "100px"
      }
    }, [_c('b-button', {
      staticClass: "px-0 rounded-lg shadow-none cursor-not-allowed",
      staticStyle: {
        "width": "50px",
        "height": "50px"
      },
      attrs: {
        "variant": "info"
      }
    }), _c('p', {
      staticClass: "mb-0 mt-50"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.Available')) + " ")])], 1), _c('div', {
      staticClass: "ml-1",
      staticStyle: {
        "width": "100px",
        "height": "100px"
      }
    }, [_c('b-button', {
      staticClass: "px-0 rounded-lg cursor-not-allowed",
      staticStyle: {
        "width": "50px",
        "height": "50px"
      },
      attrs: {
        "disabled": "",
        "variant": "secondary"
      }
    }), _c('p', {
      staticClass: "mb-0 mt-50"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.Unavailable')) + " ")])], 1)])]) : _vm._e(), !_vm.isEmpty(_vm.getSeatsMap(segment).seatFormat) ? _c('div', {
      staticClass: "w-100 d-flex flex-column overflow-auto px-1 px-lg-50",
      staticStyle: {
        "justify-content": "safe center"
      }
    }, _vm._l(_vm.getSeatsMap(segment).seatFormat, function (seatMap, indexSeatMap) {
      return _c('b-row', {
        key: indexSeatMap,
        staticClass: "mx-0 mb-1 flex-nowrap",
        staticStyle: {
          "justify-content": "safe center"
        },
        attrs: {
          "no-gutters": ""
        }
      }, _vm._l(seatMap, function (groupColumnSeat, groupColumnSeatIndex) {
        return _c('div', {
          key: groupColumnSeatIndex,
          staticClass: "d-flex flex-column align-items-center",
          class: groupColumnSeatIndex === 0 ? 'pl-1' : ''
        }, [_vm._v(" " + _vm._s(groupColumnSeat.length > 1 ? groupColumnSeat[0].col : '') + " "), _vm._l(groupColumnSeat, function (seat, seatsRowIndex) {
          return _c('div', {
            key: seatsRowIndex,
            staticClass: "d-flex-center",
            style: "height: ".concat(_vm.windowWidth > 768 ? '45px' : '34px', "; width: ").concat(_vm.windowWidth > 768 ? '45px' : '34px', ";")
          }, [_c('b-button', {
            staticClass: "px-0",
            class: _vm.setClassSeat(seat, segment.source),
            style: "height: ".concat(_vm.windowWidth > 768 ? '40px' : '30px', "; width: ").concat(_vm.windowWidth > 768 ? '40px' : '30px', "; padding: 0px;"),
            attrs: {
              "id": "seat-".concat(segmentIndex, "-").concat(indexSeatMap, "-").concat(groupColumnSeatIndex, "-").concat(seatsRowIndex, "-").concat(segment.segmentFlights, "-").concat(seat.row, "-").concat(seat.col),
              "variant": _vm.setVariantSeat(seat, segment),
              "disabled": _vm.setDisabledSeat(seat)
            },
            on: {
              "click": function click($event) {
                return _vm.handleSeatSelect(seat, segment);
              }
            }
          }, [_c('span', {
            staticClass: "text-nowrap font-weight-bolder font-small-4"
          }, [_vm._v(" " + _vm._s(seat.row) + _vm._s(seat.col) + " ")]), seat.isAvailable ? _c('b-tooltip', {
            attrs: {
              "id": "tooltip-".concat(segmentIndex, "-").concat(indexSeatMap, "-").concat(groupColumnSeatIndex, "-").concat(seatsRowIndex, "-").concat(segment.segmentFlights, "-").concat(seat.row, "-").concat(seat.col),
              "target": "seat-".concat(segmentIndex, "-").concat(indexSeatMap, "-").concat(groupColumnSeatIndex, "-").concat(seatsRowIndex, "-").concat(segment.segmentFlights, "-").concat(seat.row, "-").concat(seat.col),
              "triggers": "hover",
              "variant": "warning",
              "placement": "top",
              "boundary": "window",
              "disabled": !seat.isAvailable
            }
          }, [_c('div', {
            staticClass: "px-1 py-50"
          }, [_c('h5', {
            staticClass: "text-white pb-25 d-flex justify-content-between border-bottom text-nowrap"
          }, [_c('span', {
            staticClass: "mr-2 "
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatNumber')) + " " + _vm._s(seat.row) + _vm._s(seat.col) + " ")])]), !_vm.isEmpty(_vm.resolveLocationBySeat(seat, segment.source)) ? _c('div', {
            staticClass: "text-left"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatDescription')) + ": "), _vm._l(_vm.resolveLocationBySeat(seat, segment.source), function (location, index) {
            return _c('p', {
              key: index,
              staticClass: "mb-0"
            }, [_vm._v(" - " + _vm._s(_vm.$t("flight.".concat(location))) + " ")]);
          })], 2) : _vm._e(), _c('div', {
            staticClass: "text-left border-top mt-1"
          }, [_c('h6', {
            staticClass: "text-white py-25 mb-0 text-right"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.Price')) + ": " + _vm._s(_vm.isEmpty(seat.fares) ? 0 : _vm.formatCurrency(seat.fares[0].total)) + " ")])])])]) : _vm._e()], 1)], 1);
        })], 2);
      }), 0);
    }), 1) : _vm._e()], 1) : _c('b-row', {
      staticClass: "justify-content-center height-150 w-100 mx-0"
    }, [_c('b-spinner', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(" Loading ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }