<template>
  <b-card body-class="pt-1 pb-0 mb-0 px-0">
    <!-- NOTE - các chặng bay -->
    <div
      v-for="(segment, segmentIndex) of flatSegmentSelected"
      :key="segmentIndex"
    >
      <b-card
        v-if="['VN1A', 'VU','VJ','VZ','AK', 'QH'].includes(segment.source)"
        header-bg-variant="light-info bg-light-primary"
        header-class="info"
        body-class="py-75 px-0"
        class="border-secondary"
      >
        <app-collapse :visible="true">
          <app-collapse-item
            title
            body-class="px-0"
          >
            <!-- ANCHOR - Collapse HEADER -->
            <template #header>
              <div class="d-flex align-items-center w-100">
                <IAmLogoAirline
                  :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                  size="sm"
                  custom-class="mr-50"
                />
                <span class="font-weight-bolder font-medium-1 ml-1">
                  {{ $t('flight.flightSegment') }}:
                  <b class="text-airline">{{ segment.segmentFlights }}</b>
                  <i
                    v-if="isEmpty(getSeatsMap(segment).seatFormat)"
                    class="text-warning ml-1"
                  >
                    {{ $t('flight.notSupportSeat') }}
                  </i>
                </span>
              </div>
            </template>
            <!-- SECTION Collapse BODY -->

            <b-alert
              v-if="['QH'].includes(segment.source)"
              show
              variant="danger"
              class="py-50 px-1 shadow-sm fw-700 d-flex-center my-75"
            >
              <b>Bamboo Airways không hỗ trợ mua ghế khi thanh toán ngay, đại lý vui lòng chọn giữ chỗ nếu cần chọn ghế.</b>
            </b-alert>
            <b-row class="mx-0 pt-1 border-top-secondary">
              <!-- ANCHOR - collapse-body LEFT -->
              <b-col
                cols="12"
                lg="5"
              >
                <b-table
                  class="text-center border"
                  striped
                  hover
                  responsive
                  :items="filterSeatBySegment(segment)"
                  :fields="selectedSeatFields"
                  show-empty
                  :empty-text="$t('noMatchingResult')"
                >
                  <template #cell(passenger)="data">
                    <span class="text-truncate">
                      {{ data.item.passenger.paxType }} #{{ data.index + 1 }}
                    </span>
                  </template>
                  <template #cell(seat)="{item}">
                    <div style="">
                      <b-button
                        v-if="item.seat"
                        variant="success"
                        class="px-75"
                        :disabled="true"
                      >
                        {{ item.seat.seat.seatName }}
                      </b-button>
                      <span v-else> - </span>
                    </div>
                  </template>
                  <template #cell(price)="{item}">
                    <span
                      v-if="
                        item.seat &&
                          item.seat.seat &&
                          !isEmpty(item.seat.seat.fares)
                      "
                      class="text-truncate"
                    >
                      {{ formatCurrency(item.seat.seat.fares[0].total) }}
                    </span>
                    <span v-else> - </span>
                  </template>
                  <template #cell(action)="{item}">
                    <div class="d-flex flex-nowrap justify-content-center">
                      <b-button
                        v-if="item.seat"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="handleDeleteSeat(item)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <span v-else> - </span>
                    </div>
                  </template>
                </b-table>
              </b-col>

              <!-- ANCHOR - collapse-body RIGHT: Show Seat Map -->
              <b-col
                cols="12"
                lg="7"
                class="px-0"
              >
                <!-- <vue-perfect-scrollbar
                    :settings="perfectScrollbarSettings"
                    style="min-height: 15em; max-height: 50vh"
                  > -->
                <!-- NOTE SEAT-MAP -->
                <b-row
                  v-if="getSeatsMap(segment)"
                  class="mx-0"
                  no-gutters
                >
                  <!-- NOTE Tên máy bay và trạng thái ghế -->
                  <b-col
                    v-if="getSeatsMap(segment).dataAirCraftBySegment"
                    cols="12"
                    class="text-center my-2"
                  >
                    <p class="text-airline font-weight-bolder font-large-1 mb-1">
                      {{ getAirCraftByIata(getSeatsMap(segment)?.dataAirCraftBySegment?.seatsMap?.aircraft)
                        || getSeatsMap(segment)?.dataAirCraftBySegment?.seatsMap?.aircraft }}
                    </p>
                    <div class="d-flex justify-content-center">
                      <div
                        class="mr-1"
                        style="width: 100px; height: 100px"
                      >
                        <b-button
                          class="px-0 rounded-lg shadow-none cursor-not-allowed"
                          style="width: 50px; height: 50px"
                          variant="info"
                        />
                        <p class="mb-0 mt-50">
                          {{ $t('flight.Available') }}
                        </p>
                      </div>
                      <div
                        class="ml-1"
                        style="width: 100px; height: 100px"
                      >
                        <b-button
                          class="px-0 rounded-lg cursor-not-allowed"
                          style="width: 50px; height: 50px"
                          disabled
                          variant="secondary"
                        />
                        <p class="mb-0 mt-50">
                          {{ $t('flight.Unavailable') }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                  <!-- NOTE - SEAT-MAP -->
                  <div
                    v-if="!isEmpty(getSeatsMap(segment).seatFormat)"
                    class="w-100 d-flex flex-column overflow-auto px-1 px-lg-50"
                    style="justify-content: safe center;"
                  >
                    <b-row
                      v-for="(seatMap, indexSeatMap) of getSeatsMap(segment).seatFormat"
                      :key="indexSeatMap"
                      class="mx-0 mb-1 flex-nowrap"
                      style="justify-content: safe center;"
                      no-gutters
                    >
                      <div
                        v-for="(groupColumnSeat, groupColumnSeatIndex) of seatMap"
                        :key="groupColumnSeatIndex"
                        class="d-flex flex-column align-items-center"
                        :class="groupColumnSeatIndex === 0 ? 'pl-1' : ''"
                      >
                        {{ groupColumnSeat.length > 1 ? groupColumnSeat[0].col : '' }}
                        <!-- NOTE - GHẾ TRONG CỘT -->
                        <div
                          v-for="(seat, seatsRowIndex) in groupColumnSeat"
                          :key="seatsRowIndex"
                          class="d-flex-center"
                          :style="`height: ${windowWidth>768?'45px':'34px'}; width: ${windowWidth>768?'45px':'34px'};`"
                        >
                          <b-button
                            :id="`seat-${segmentIndex}-${indexSeatMap}-${groupColumnSeatIndex}-${seatsRowIndex}-${segment.segmentFlights}-${seat.row}-${seat.col}`"
                            class="px-0"
                            :style="`height: ${windowWidth>768?'40px':'30px'}; width: ${windowWidth>768?'40px':'30px'}; padding: 0px;`"
                            :variant="setVariantSeat(seat, segment)"
                            :disabled="setDisabledSeat(seat)"
                            :class="setClassSeat(seat, segment.source)"
                            @click="handleSeatSelect(seat, segment)"
                          >
                            <span class="text-nowrap font-weight-bolder font-small-4">
                              {{ seat.row }}{{ seat.col }}
                            </span>
                            <b-tooltip
                              v-if="seat.isAvailable"
                              :id="`tooltip-${segmentIndex}-${indexSeatMap}-${groupColumnSeatIndex}-${seatsRowIndex}-${segment.segmentFlights}-${seat.row}-${seat.col}`"
                              :target="`seat-${segmentIndex}-${indexSeatMap}-${groupColumnSeatIndex}-${seatsRowIndex}-${segment.segmentFlights}-${seat.row}-${seat.col}`"
                              triggers="hover"
                              variant="warning"
                              placement="top"
                              boundary="window"
                              :disabled="!seat.isAvailable"
                            >
                              <div class="px-1 py-50">
                                <h5 class="text-white pb-25 d-flex justify-content-between border-bottom text-nowrap">
                                  <span class="mr-2 ">
                                    {{ $t('flight.seatNumber') }}
                                    {{ seat.row }}{{ seat.col }}
                                  </span>
                                </h5>
                                <div
                                  v-if="!isEmpty(resolveLocationBySeat(seat, segment.source))"
                                  class="text-left"
                                >
                                  {{ $t('flight.seatDescription') }}:
                                  <p
                                    v-for="(location, index) of resolveLocationBySeat(seat, segment.source)"
                                    :key="index"
                                    class="mb-0"
                                  >
                                    - {{ $t(`flight.${location}`) }}
                                  </p>
                                </div>
                                <div class="text-left border-top mt-1">
                                  <h6 class="text-white py-25 mb-0 text-right">
                                    {{ $t('flight.Price') }}:
                                    {{ isEmpty(seat.fares) ? 0 : formatCurrency(seat.fares[0].total) }}
                                  </h6>
                                </div>
                              </div>
                            </b-tooltip>
                          </b-button>
                        </div>
                      </div>
                    </b-row>
                  </div>
                </b-row>

                <!-- NOTE loading -->
                <b-row
                  v-else
                  class="justify-content-center height-150 w-100 mx-0"
                >
                  <b-spinner variant="info">
                    Loading
                  </b-spinner>
                </b-row>
                <!-- </vue-perfect-scrollbar> -->
              </b-col>
            </b-row>
            <!-- !SECTION -->
          </app-collapse-item>
        </app-collapse>
      </b-card>
    </div>
  </b-card>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BTable,
  BButton,
  BTooltip,
  VBTooltip,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import { computed, ref, toRefs } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Ripple from 'vue-ripple-directive'
import { useWindowSize } from '@vueuse/core'

import store from '@/store'
import { resolveCabinDetailsCreateBooking, resolveLocationBySeat } from '@/constants/selectOptions'

import { formatCurrency } from '@core/utils/filter'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BRow,
    BCol,
    BTooltip,
    BCard,
    BButton,
    BTable,
    BSpinner,
    BAlert,

    AppCollapse,
    AppCollapseItem,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    seatMapData: {
      type: Array,
      default: () => [],
    },
    selectedTrip: {
      type: Array,
      default: () => [],
    },
    passengerDataToAdd: {
      type: Array,
      default: () => [],
    },
    isCombination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { FLIGHT_APP_STORE_MODULE_NAME } = useBookingHandle()
    const { width: windowWidth } = useWindowSize()
    const { isCombination } = toRefs(props)

    const selectedSeatFields = ['passenger', 'seat', 'price', 'action']
    const passengerSeatMapData = ref([])
    const isLoadingData = ref(false)

    const getAirCraftByIata = code => store.getters['globalConfig/getAirCraftByIata'](code)

    // ANCHOR Table Seat Passengers
    function filterSeatBySegment(segment) {
      const dataPaxSeat = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getPaxSeatData`])

      if (!isEmpty(dataPaxSeat.value)) {
        return dataPaxSeat.value.filter(
          s => s.segment.segmentFlights === segment.segmentFlights
            && ((['VN1A'].includes(segment.source) && !isCombination.value)
              ? (s.segment.airlineItineraryId === segment.airlineItineraryId)
              : ['AK'].includes(segment.source)
                ? (s.segment.segmentId === segment.segmentId)
                : true),
        )
      }

      return []
    }

    // NOTE - FLAT SEGMENT
    const flatSegmentSelected = computed(() => {
      if (!isEmpty(props.selectedTrip)) {
        let airlineSegmentId = 1
        const result = props.selectedTrip.map((trip, tripIndex) => trip.segments.map(segment => {
          const dataSegment = {
            ...segment,
            ...(['VN1A', 'VJ'].includes(trip.source) && { groupClass: trip.fareOptions.groupClass }),
            ...((['VN1A'].includes(trip.source) && isCombination.value) && {
              source: trip.source,
              airlineItineraryId: String(tripIndex + 1),
              airlineSegmentId: String(airlineSegmentId),
            }),
          }
          airlineSegmentId += 1
          return dataSegment
        }))
        return result.flat().map(segment => {
          const segmentFlights = `${segment.departure.IATACode}-${segment.arrival.IATACode}`
          return {
            ...segment,
            cabinClass: ['VN'].includes(segment.airline) ? segment.bookingClass : segment.groupClass,
            segmentFlights,
          }
        })
      }
      return []
    })

    // SECTION MAPPING DATA SEATMAP ==============================================================================
    // ANCHOR -  SP (gộp col)
    function addSeatToLayout(config, seats) {
      const result = []
      let index = 0
      for (let i = 0; i < config.length; i += 1) {
        const row = []
        if (config[i] !== '0') {
          for (let j = 0; j < Number(config[i]); j += 1) {
            row.push(seats[index])
            index += 1
          }
          result.push(row)
        }
      }
      return result
    }

    // ANCHOR -  SP (check row và thêm các ghế trống vào Arr seats)
    function findEmptyItemArray(newArr, { source, aircraft }) {
      const arrSeat = newArr

      for (let i = 0; i < arrSeat.length; i += 1) {
        if (arrSeat[i + 1] && Number(arrSeat[i].row) + 1 < arrSeat[i + 1].row) {
          const row = Number(arrSeat[i].row) + 1
          if (!['VJ'].includes(source) || (['VJ'].includes(source) && row !== 13 && ['321', '330'].includes(aircraft)) || (['VJ'].includes(source) && row === 13 && ['320'].includes(aircraft))) {
            arrSeat.splice(i + 1, 0, {
              isAvailable: false,
              row,
              col: arrSeat[i].col,
              location: 'NoSeatAtThisLocation',
              fares: [],
            })
          }
        }
      }

      if (!['VJ'].includes(source)) return arrSeat

      if (['320', '321', '330'].includes(aircraft)) {
        for (let i = 0; i < arrSeat.length; i += 1) {
          const row = Number(arrSeat[i].row) + 1
          const addRow = ['320'].includes(aircraft) ? [12].includes(row) : ['321'].includes(aircraft) ? [11, 27].includes(row) : ['330'].includes(aircraft) ? [15, 34].includes(row) : false

          if (addRow) {
            arrSeat.splice(i + 1, 0, {
              isAvailable: false,
              row,
              col: arrSeat[i].col,
              location: 'NoSeatAtThisLocation',
              fares: [],
            })
          }
        }
      }

      return arrSeat
    }

    function convertSeats(cabinConfig, data) {
      const seats = addSeatToLayout(cabinConfig, data)
      const result = []
      let emptyCount = 0
      for (let i = 0; i < seats.length; i += 1) {
        const row = seats[i]
        if (row.length === 0) {
          emptyCount += 1
          if (emptyCount === 1) {
            result.push([
              [
                {
                  row: 'AISLE_LOCATION',
                  col: 'AISLE_LOCATION',
                  location: 'NoSeatAtThisLocation',
                },
              ],
            ])
          }
        } else {
          emptyCount = 0
          result.push(row)
        }
      }
      return [].concat(...result)
    }

    // ANCHOR -  SP (Lấy ra cabin class theo groupClass)
    function findSeatsMapBySegment(seatsMap, segment) {
      try {
        const getSegmentFlights = seatsMap.find(item => (item.flights === segment.segmentFlights)
          && ((['VN1A'].includes(segment.source) && !isCombination.value)
            ? (String(item.itineraryId) === String(segment.airlineItineraryId))
            : ['AK', 'VJ'].includes(segment.source)
              ? item.segmentId === segment.segmentId
              : true))

        if (getSegmentFlights) {
          const { cabinDetails, ...rest } = getSegmentFlights
          const data = {
            ...rest,
            // cabinDetails: resolveCabinDetails(cabinDetails, segment), // NOTE: VJ no cabin value
            cabinData: ['VN1A', 'AK', 'QH'].includes(segment.source) ? resolveCabinDetailsCreateBooking(cabinDetails, segment) : [resolveCabinDetailsCreateBooking(cabinDetails, segment)],
          }

          return data
        }
        return null
      } catch (error) {
        console.error('Error findSeatsMapBySegment::', error)
        return null
      }
    }

    // NOTE - Gộp các cột ghế
    // const cabinDetail = dataAirCraftBySegment.seatsMap.cabinDetails
    const groupColumnSeat = (cabinDetail, { source, aircraft }) => {
      const columnCount = (cabinDetail.cabinConfig ?? '')?.split('-')?.reduce((sum, val) => sum + Number(val), 0)
      const columnLetters = cabinDetail?.cabinConfigInternal?.split('-')
      const columns = Array?.from({ length: columnCount }, () => [])
      cabinDetail.seatOptions.forEach(option => {
        const letterIndex = columnLetters.indexOf(option.col)
        const colIndex = letterIndex >= 0
          ? letterIndex
          : Number(option.col) - 1 + columnLetters.length
        if (columns[colIndex]) {
          columns[colIndex].push(option)
        }
      })
      for (let i = 0; i < columns.length; i += 1) {
        if (columns[i].length) {
          columns[i] = findEmptyItemArray(columns[i], { source, aircraft })
        }
      }
      const seatFormat = columns.map(item => item.filter(seat => String(seat.col) !== '0'))
      return seatFormat
    }

    // NOTE get các list segment có máy bay của hãng theo segment của hành trình
    const getSeatsMap = segment => {
      const airCraftByAirline = props.seatMapData.find(item => item.source === segment.source)

      // NOTE Data AirCraft đã lấy đến cabin theo segment
      if (airCraftByAirline) {
        const dataAirCraftBySegment = {
          airline: airCraftByAirline.airline,
          source: airCraftByAirline.source,
          seatsMap: findSeatsMapBySegment(airCraftByAirline.seatsMap, segment),
        }
        const data = {
          dataAirCraftBySegment,
          seatFormat: dataAirCraftBySegment?.seatsMap?.cabinData?.map(s => convertSeats(s?.cabinConfig, groupColumnSeat(s, { source: dataAirCraftBySegment?.source, aircraft: dataAirCraftBySegment?.seatsMap?.aircraft }))),
          passenger: 'Import passenger',
        }

        return data
      }
      return []
    }
    // !SECTION

    // SECTION ====================== Variant, Disabled
    const selectedSeatsDetails = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSelectedSeatsDetails`])

    function setVariantSeat(seat, segment) {
      let variant = 'relief-info'
      const seatName = `${seat.row}${seat.col}`
      if (!seat.isAvailable) {
        variant = 'secondary'
      }
      if (!isEmpty(selectedSeatsDetails.value)) {
        const findExist = selectedSeatsDetails.value.find(ss => {
          const result = ss.seat.segmentFlights === segment.segmentFlights
                && ss.seat.seat.seatName === seatName
                && (['AK', 'VN1A'].includes(segment.source) ? (ss.segment.airlineItineraryId === segment.airlineItineraryId) : true)
          return result
        })
        if (findExist) {
          variant = 'relief-success'
        }
      }
      return variant
    }
    function setDisabledSeat(seat) {
      let setDisabled = false
      if (!seat.isAvailable) {
        setDisabled = true
      }
      return setDisabled
    }
    function setClassSeat(seat, source) {
      const seatClass = []
      if (seat.location === 'NoSeatAtThisLocation') {
        seatClass.push('d-none')
      }
      if (!seat.isAvailable) {
        seatClass.push('cursor-not-allowed')
      }
      if (resolveLocationBySeat(seat, source).includes('ExitRowSeat')) {
        seatClass.push('border-top-danger-4')
      }
      return seatClass.join(' ')
    }
    // !SECTION

    // SECTION ====================== HANDLE SEAT
    function handleSeatSelect(seat, segment) {
      const dataSeatSelect = {
        segmentFlights: segment.segmentFlights,
        segmentId: String(segment.airlineSegmentId),
        itineraryId: ['VU'].includes(segment.source) ? segment.segmentValue : segment.airlineItineraryId,
        source: segment.source,
        airline: segment.airline === 'VZ' ? 'VJ' : segment.airline,
        seat: {
          seatName: `${seat.row}${seat.col}`,
          fares: seat.fares,
          seatValue: seat?.seatValue || null,
        },
      }
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/handleSeatSelect`, dataSeatSelect)
    }

    function handleDeleteSeat({ seat, segment }) {
      const dataSeatSelect = {
        segmentFlights: segment.segmentFlights,
        itineraryId: segment?.airlineItineraryId,
        source: segment.source,
        seat: seat.seat,
      }
      store.dispatch(
        `${FLIGHT_APP_STORE_MODULE_NAME}/handleSeatSelect`,
        dataSeatSelect,
      )
    }
    // !SECTION

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      formatCurrency,
      windowWidth,
      isEmpty,
      resolveLocationBySeat,
      perfectScrollbarSettings,
      isLoadingData,
      getAirCraftByIata,

      flatSegmentSelected,
      selectedSeatFields,
      getSeatsMap,
      handleSeatSelect,

      handleDeleteSeat,
      passengerSeatMapData,
      setVariantSeat,
      setClassSeat,
      setDisabledSeat,
      filterSeatBySegment,
    }
  },
}
</script>
